import React from "react";
import {
    Box,
    Typography,
    Stack,
} from "@mui/material";
import TrashIcon from "@mui/icons-material/Delete";
import { defaultCard, Logo } from "../../assets";

export default function FileCard({ file, image, frontImg, created_date, setOpenFileDel, setFileId, isManager }) {

    const project = () => {
        const link = `${process.env.REACT_APP_KEY}file/${file.id}`;
        switch (file.extension) {
            case "pdf":
                return (
                    <Box sx={cardContainer}>
                        {isManager && (
                            <TrashIcon
                                style={trashIcon}
                                onClick={() => {
                                    setOpenFileDel(true);
                                    setFileId(file.id);
                                }}
                            />
                        )}
                        <a
                            href={link}
                            target="_blank"
                            rel="noReferrer"
                            style={{ color: "black", textDecoration: "none" }}
                        >
                            <Box
                                sx={[
                                    cardStyle,
                                    {
                                        backgroundImage: file.thumbnail_url
                                            ? `url(${file.thumbnail_url})`
                                            : `url{${defaultCard}}`
                                    },
                                ]}
                            >
                                <Box sx={cardInner}>
                                    <Box sx={imgWrapper}>
                                        <img
                                            style={smallLogo}
                                            src={frontImg ? frontImg : Logo}
                                            alt="logo"
                                            width="150px"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={cardFoo}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Box>
                                        <Typography
                                            variant="h6"
                                            component="h6"
                                            fontSize="16px"
                                            textAlign="left"
                                        >
                                            {file.name}
                                        </Typography>
                                        <Typography
                                            variant="p"
                                            component="p"
                                            fontSize="14px"
                                            textAlign="left"
                                        >
                                            {file.size}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="p"
                                        component="p"
                                        fontSize="14px"
                                        textAlign="left"
                                    >
                                        {created_date}
                                    </Typography>
                                </Stack>
                            </Box>
                        </a>
                    </Box>
                );
            case "png":
            case "jpg":
            case "jpeg":
                return (
                    <Box sx={cardContainer}>
                        {isManager && (
                            <TrashIcon
                                style={trashIcon}
                                onClick={() => {
                                    setOpenFileDel(true);
                                    setFileId(file.id);
                                }}
                            />
                        )}
                        <a
                            href={file.file_url}
                            target="_blank"
                            rel="noReferrer"
                            style={{ color: "black", textDecoration: "none" }}
                        >
                            <Box
                                sx={[
                                    cardStyle,
                                    {
                                        backgroundImage: file.file_url
                                            ? `url(${file.file_url})`
                                            : `url{${defaultCard}}`
                                    },
                                ]}
                            >
                                <Box sx={cardInner}>
                                    <Box sx={imgWrapper}>
                                        <img
                                            src={frontImg ? frontImg : Logo}
                                            alt="logo"
                                            width="150px"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={cardFoo}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Box>
                                        <Typography
                                            variant="h6"
                                            component="h6"
                                            fontSize="16px"
                                            textAlign="left"
                                        >
                                            {file.name}
                                        </Typography>
                                        <Typography
                                            variant="p"
                                            component="p"
                                            fontSize="14px"
                                            textAlign="left"
                                        >
                                            {file.size}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="p"
                                        component="p"
                                        fontSize="14px"
                                        textAlign="left"
                                    >
                                        {created_date}
                                    </Typography>
                                </Stack>
                            </Box>
                        </a>
                    </Box>
                );
            case "doc":
            case "docx":
            case "zip":
            case "svg":
                return (
                    <Box sx={cardContainer}>
                        {isManager && (
                            <TrashIcon
                                style={trashIcon}
                                onClick={() => {
                                    setOpenFileDel(true);
                                    setFileId(file.id);
                                }}
                            />
                        )}
                        <a
                            href={file.file_url}
                            target="_blank"
                            rel="noReferrer"
                            style={{ color: "black", textDecoration: "none" }}
                        >
                            <Box
                                sx={[
                                    cardStyle,
                                    {
                                        backgroundImage: image
                                            ? `url(${image})`
                                            : `url{${defaultCard}}`
                                    },
                                ]}
                            >
                                <Box sx={cardInner}>
                                    <Box sx={imgWrapper}>
                                        <img
                                            src={frontImg ? frontImg : Logo}
                                            alt="logo"
                                            width="150px"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={cardFoo}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Box>
                                        <Typography
                                            variant="h6"
                                            component="h6"
                                            fontSize="16px"
                                            textAlign="left"
                                        >
                                            {file.name}
                                        </Typography>
                                        <Typography
                                            variant="p"
                                            component="p"
                                            fontSize="14px"
                                            textAlign="left"
                                        >
                                            {file.size}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="p"
                                        component="p"
                                        fontSize="14px"
                                        textAlign="left"
                                    >
                                        {created_date}
                                    </Typography>
                                </Stack>
                            </Box>
                        </a>
                    </Box>
                );
            case "csv":
                return (
                    <Box sx={cardContainer}>
                        {isManager && (
                            <TrashIcon
                                style={trashIcon}
                                onClick={() => {
                                    setOpenFileDel(true);
                                    setFileId(file.id);
                                }}
                            />
                        )}
                        <a
                            href={file.file_url}
                            target="_blank"
                            rel="noReferrer"
                            style={{ color: "black", textDecoration: "none" }}
                        >
                            <Box
                                sx={[
                                    cardStyle,
                                    {
                                        backgroundImage: image
                                            ? `url(${image})`
                                            : `url{${defaultCard}}`
                                    },
                                ]}
                            >
                                <Box sx={cardInner}>
                                    <Box sx={imgWrapper}>
                                        <img
                                            src={frontImg ? frontImg : Logo}
                                            alt="logo"
                                            width="150px"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={cardFoo}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Box>
                                        <Typography
                                            variant="h6"
                                            component="h6"
                                            fontSize="16px"
                                            textAlign="left"
                                        >
                                            {file.name}
                                        </Typography>
                                        <Typography
                                            variant="p"
                                            component="p"
                                            fontSize="14px"
                                            textAlign="left"
                                        >
                                            {file.size}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="p"
                                        component="p"
                                        fontSize="14px"
                                        textAlign="left"
                                    >
                                        {created_date}
                                    </Typography>
                                </Stack>
                            </Box>
                        </a>
                    </Box>
                );
            case "xlsx":
                return (
                    <Box sx={cardContainer}>
                        {isManager && (
                            <TrashIcon
                                style={trashIcon}
                                onClick={() => {
                                    setOpenFileDel(true);
                                    setFileId(file.id);
                                }}
                            />
                        )}
                        <a
                            href={file.file_url}
                            target="_blank"
                            rel="noReferrer"
                            style={{ color: "black", textDecoration: "none" }}
                        >
                            <Box
                                sx={[
                                    cardStyle,
                                    {
                                        backgroundImage: image
                                            ? `url(${image})`
                                            : `url{${defaultCard}}`
                                    },
                                ]}
                            >
                                <Box sx={cardInner}>
                                    <Box sx={imgWrapper}>
                                        <img
                                            src={frontImg ? frontImg : Logo}
                                            alt="logo"
                                            width="150px"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={cardFoo}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Box>
                                        <Typography
                                            variant="h6"
                                            component="h6"
                                            fontSize="16px"
                                            textAlign="left"
                                        >
                                            {file.name}
                                        </Typography>
                                        <Typography
                                            variant="p"
                                            component="p"
                                            fontSize="14px"
                                            textAlign="left"
                                        >
                                            {file.size}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="p"
                                        component="p"
                                        fontSize="14px"
                                        textAlign="left"
                                    >
                                        {created_date}
                                    </Typography>
                                </Stack>
                            </Box>
                        </a>
                    </Box>
                );
            default:
                return "";
        }
    };

    return (
        <>
            <Box className="file">{project()}</Box>
        </>
    );
}

const cardAnimation = {
    transition: "all 150ms ease",
    boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 4px 2px",
};
const cardContainer = {
    boxShadow: "rgba(0, 0, 0, 0.4) 1px 1px 4px 1px",
    position: "relative",
    borderRadius: "12px",
    mb: 2,
    cursor: "pointer",
    height: "100%",
    "&:hover": cardAnimation,
};
const cardStyle = {
    width: "100%",
    minHeight: "250px",
    display: "flex",
    justifyContent: "flex-end",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
};
const cardInner = {
    backgroundColor: "#ffffffb0",
    p: 1,
    width: "100%",
    minHeight: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
};
const imgWrapper = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "150px",
};
const cardFoo = {
    backgroundColor: "#fff",
    px: 1,
    py: 1,
};
const trashIcon = {
    position: "absolute",
    top: "-14px",
    right: "-12px",
    zIndex: 999,
    cursor: 'pointer',
    color: '#000',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    backgroundColor: '#fff',
    padding: '10px',
    border: '1px solid #D5D5D5'
}
const smallLogo = {
    width: "auto",
    height: "24px",
};