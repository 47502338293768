import React, { useEffect, useState } from 'react';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import {
    Box, Stack, Typography
} from '@mui/material';
import Tick from '@mui/icons-material/CheckOutlined';
import API from '../../axios';

export default function CusProgress({ lastProgress = 0, progress = 0, pStatus }) {
    // TODO: Fix default values by using cached array
    const [status, setStatus] = useState([{id:"", title:""}, {id:"", title:""}, {id:"", title:""}, {id:"", title:""}, {id:"", title:""}, {id:"", title:""}, {id:"", title:""}]); // TODO: Cache state to avoid latency reloading of page
    let Flag = false;
    let FlagFound = false;

    useEffect(() => {
        getStatus();
    }, [])

    const getStatus = async () => {
        try {
            let { data } = await API("get", "get-status-and-staff");
            setStatus(data?.project_statuses);
        } catch (err) {
            console.log("ERR", err)
        }
    }


    return (
        <Box id="ProgressBarBox" sx={{ textAlign: "center" }}>
            <Progress 
                type="circle"
                width={120}
                height={120}
                status="active"
                theme={{
                    active: {
                        trailColor: '#F7F7F7',
                        color: '#FFBB00'
                    }
                }}
                percent={progress} />
            <Stack
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
                mt={2}
            >
                {status?.map((_v, _i) => {
                    if (_v.id === pStatus) {
                        Flag = true;
                    }
                    return (
                        <Box sx={{ textAlign: "center", mr: 2 }} key={_i}>
                            {
                                !Flag && !FlagFound
                                    ?
                                    <>
                                        <Tick color='primary' sx={{ fontSize: 25, textAlign: "center" }} />
                                        <Box sx={statusCon}>
                                            <Typography variant='small' sx={statusText}>
                                                {_v.title}
                                            </Typography>
                                        </Box>
                                    </>
                                    : Flag && !FlagFound ?
                                        <>
                                            {FlagFound = true}
                                            <Tick color='primary' sx={{ fontSize: 25, textAlign: "center" }} />
                                            <Box sx={statusCon}>
                                                <Typography variant='small' sx={statusText}>
                                                    {_v.title}
                                                </Typography>
                                            </Box>
                                        </> : Flag && FlagFound ?
                                            <>
                                                <Box sx={{ height: "2em" }}></Box>
                                                <Box sx={[statusCon, { borderColor: '#ccc' }]}>
                                                    <Typography variant='small' sx={statusText}>
                                                        {_v.title}
                                                    </Typography>
                                                </Box>
                                            </>
                                            : null
                            }
                        </Box>
                    )
                })}
            </Stack>
        </Box>
    )
}

const statusCon = {
    border: 2,
    borderColor: "#FFBB00",
    borderRadius: 2,
    paddingRight: 1.5,
    paddingLeft: 1.5,
    height: "44px",
    width: "160px",
    boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 2px 0px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    marginBottom: "6px"
}

const statusText = {
    fontSize: 11,
    color: "#969696",
    wordWrap: "break-word",
}