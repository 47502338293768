import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "../layout";
import { SplashScreen } from "../components";
import { appRoutes } from "./app-routes";
import { useSelector, useDispatch } from "react-redux";
import { SignIn, SetPassword, QrViewer } from "../pages";
import API from "../axios";
import {
  langSetter,
  openPopUp,
  setProStatus,
  storeUser,
  Translation,
} from "../store/reducer";

export default function Router() {
  const [isLoading, setIsLoading] = useState(false);
  const { isLogged, user } = useSelector((state) => state.storeReducer);
  const _token = localStorage.getItem("@ACCESS_TOKEN");
  const _lan = localStorage.getItem("language");
  const _project_status = localStorage.getItem("@Project_Status");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!_token) {
      getUser();
    }
    let v12 = getTranslation();
    if (v12 === true) {
      setIsLoading(false);

    }
  }, []);

  const getUser = async () => {
    try {
      let { data } = await API("get", "me");
      dispatch(storeUser(data));
      if (_project_status !== null) {
        dispatch(setProStatus(_project_status));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getTranslation = async () => {
    try {
      const res = await API("get", "get-csvfile");
      dispatch(Translation(res?.data));
      if (!_lan) {
        dispatch(langSetter("german"));
      } else {
        dispatch(langSetter(_lan));
      }
      return true;
    } catch (err) {
      dispatch(
        openPopUp({
          message: err?.response?.data?.message || "Translations not load!",
          type: "error",
        })
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <Routes>
          <Route
            path="/sign-in"
            exact
            element={
              <CheckUser flag={isLogged} user={user}>
                <SignIn />
              </CheckUser>
            }
          />
          <Route path="/set-password/:token" exact element={<SetPassword />} />
          <Route path="/qr" exact element={<QrViewer />} />
          {appRoutes.map((_v, _i) => {
            return (
              <Route
                key={_i}
                path={_v.path}
                exact
                element={
                  <Protected
                    isLogged={isLogged}
                    user={user}
                    ele={_v}
                    children={_v.component}
                  />
                }
              />
            );
          })}
          <Route path="*" element={<SignIn />} />
        </Routes>
      )}
    </>
  );
}

const Protected = ({ isLogged, ele, user, children }) => {
  const role = user?.role || null;
  if (!isLogged) {
    return <Navigate to="/sign-in" replace />;
  }
  if (ele.type === "CLIENT" && role === "CLIENT") {
    return ele.layout ? <Layout children={children} /> : children;
  } else if (ele.type === "SUPER_ADMIN" && role === "SUPER_ADMIN") {
    return ele.layout ? <Layout children={children} /> : children;
  } else if (ele.type === "ADMIN" && role === "ADMIN") {
    return ele.layout ? <Layout children={children} /> : children;
  } else {
    if (role === "CLIENT") return <Navigate to="/files" replace />;
    else if (role === "SUPER_ADMIN") return <Navigate to="/" replace />;
    else if (role === "ADMIN") return <Navigate to="/projects" replace />;
    else return <Navigate to="*" replace />;
  }
};
const CheckUser = ({ flag, user, children }) => {
  const role = user?.role || null;
  if (flag) {
    if (role === "CLIENT") return <Navigate to="/files" replace />;
    else if (role === "SUPER_ADMIN") return <Navigate to="/" replace />;
    else if (role === "ADMIN") return <Navigate to="/projects" replace />;
    else return <Navigate to="/sign-in" replace />;
  }
  return children;
};
