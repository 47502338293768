import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import PageTitle from "../../../hooks/page-title";
import API from "../../../axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProStatus } from "../../../store/reducer";
import { CusProgress } from "../../../components";

export default function AdDropFiles() {
  PageTitle("Project");
  const [categories, setCategories] = useState(null);
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.storeReducer);
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getCategories = async () => {
    setIsLoading(true);
    try {
      let { data: categoryContainer } = await API(
        "get",
        `categories?active=1&user_id=${user.id}`
      );
      let nonemptyCategories = [];
      categoryContainer.categories?.forEach((cat) => {
        if (cat.files.length > 0) {
          nonemptyCategories.push(cat);
        }
      });

      if (nonemptyCategories.length === 0) {
        nonemptyCategories.push(categoryContainer.categories[0]);
      }
      setProject(categoryContainer?.project);
      setCategories(nonemptyCategories);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="false" sx={{ pt: 3, pb: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {project?.project_status === null ? null :
            project?.project_status?.progress === 0 ? null :
              <CusProgress progress={project?.project_status?.progress} pStatus={project?.project_status_id} />
          }
        </Grid>
        <Grid item xs={12}>
          <Box mb={3}>
            <Stack direction="row">
              <Typography variant="h6">{language[selectedLang]?.drop_files_files}</Typography>
            </Stack>
          </Box>
          {/* <Typography
            variant="small"
            color="#FFBB00"
            sx={{ marginBottom: 2, marginLeft: 4 }}
          >
            {language[selectedLang]?.drop_files_files} /
          </Typography> */}
        </Grid>

        {isLoading ? (
          <Grid item xs={12}>
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress size={22} />
            </Box>
          </Grid>
        ) : (
          !!categories &&
          categories?.map((v, i) => {
            return (
              <Grid item lg={4} sm={6} xs={12} key={i} sx={hoverAnimation}>
                <Box sx={cardContainer}>
                  <Box
                    sx={[
                      cardStyle,
                      {
                        backgroundImage: user.image_url
                          ? `url(${user.image_url})`
                          : `url(${v.bg_url})`,
                      },
                    ]}
                    onClick={() => {
                      dispatch(setProStatus(project?.project_status?.title));
                      navigate(`/category/files`, {
                        state: {
                          id: user.id,
                          category_id: v.id,
                          project: project,
                          progress: project?.project_status
                            ? project?.project_status?.progress
                            : 0,
                        },
                      });
                    }}
                  >

                  </Box>
                  <Box sx={cardFoo}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={2}
                      sx={{ minHeight: "50px" }}
                    >
                      <Box sx={imageFrame}>
                        <img
                          style={smallLogo}
                          src={v.image_url}
                          alt="logo"
                          width="100%"
                          height="100%"
                        />
                      </Box>
                      <Typography
                        sx={{ fontSize: 20, fontWeight: "bold" }}
                        variant="small"
                      >
                        {v.name}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </Grid>
            );
          })
        )}
      </Grid>
    </Container>
  );
}

const cardAnimation = {
  transition: "all 150ms ease",
  boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 4px 2px",
};
const cardContainer = {
  boxShadow: "rgba(0, 0, 0, 0.4) 1px 1px 4px 1px",
  borderRadius: "12px",
  position: "relative",
  overflow: "hidden",
  mb: 2,
  cursor: "pointer",
  "&:hover": cardAnimation,
};
const cardStyle = {
  width: "100%",
  minHeight: "250px",
  display: "flex",
  justifyContent: "flex-end",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
};
const cardFoo = {
  backgroundColor: "#fff",
  px: 1,
  py: 1,
};
const hoverAnimation = {
  "&:hover": {
    transition: "all 150ms ease",
    zIndex: 99,
    transform: "scale(1.006)",
  },
};

const imageFrame = {
  width: "50px",
  position: "relative",
};
const smallLogo = {
  width: "auto",
  height: "24px",
};
