import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    Box,
    Typography,
    Stack,
    CircularProgress
} from '@mui/material'
import { useSelector } from 'react-redux';
import API from '../../../axios';
import { Logo } from '../../../assets/index';
export default function ADStaffs() {
    const [staffs, setStaffs] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { selectedLang, language } = useSelector((state) => state.storeReducer);
    useEffect(() => {
        getStaff();
    }, []);

    const getStaff = async () => {
        setIsLoading(true);
        try {
            let { data } = await API(
                "get",
                `admin/staffs`
            );
            setStaffs(data?.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    return (
        <Container maxWidth="false" sx={{ pt: 3, pb: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box mb={3}>
                        <Typography variant="h6">
                            {language[selectedLang]?.admin_staffs}
                        </Typography>
                    </Box>
                </Grid>
                {isLoading ? (
                    <Grid item xs={12}>
                        <Box sx={{ textAlign: "center" }}>
                            <CircularProgress size={22} />
                        </Box>
                    </Grid>)
                    :
                    (!!staffs &&
                        staffs?.map((_v, _i) => (
                            <Grid item lg={4} sm={6} xs={12} key={_i}>
                                <Box sx={cardContainer}>
                                    <Box
                                        sx={[
                                            cardStyle,
                                            {
                                                backgroundImage: _v?.image_url
                                                    ? `url(${_v?.image_url})`
                                                    : `url(${Logo})`,
                                            },
                                        ]}
                                    >
                                    </Box>
                                    <Box sx={cardFoo}>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Typography
                                                mb={1}
                                                sx={{ fontSize: 20, fontWeight: "bold" }}
                                                variant="small"
                                            >
                                                {_v?.first_name} {_v?.last_name}
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Typography
                                                mb={1}
                                                sx={{ fontSize: 14, fontWeight: "bold" }}
                                                variant="small"
                                            >
                                                {_v?.email ? _v?.email : "--"}
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Typography
                                                mb={1}
                                                sx={{ fontSize: 14, fontWeight: "bold" }}
                                                variant="small"
                                            >
                                                {_v?.description ? _v?.description : "--"}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Grid>
                        )))
                }
            </Grid>
        </Container>
    )
}

const cardAnimation = {
    transition: "all 150ms ease",
    boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 4px 2px",
    zIndex: 99,
    transform: "scale(1.006)",
};

const cardContainer = {
    boxShadow: "rgba(0, 0, 0, 0.4) 1px 1px 4px 1px",
    borderRadius: "12px",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    height: "100%",
    "&:hover": cardAnimation,
};
const cardStyle = {
    width: "100%",
    minHeight: "250px",
    display: "flex",
    justifyContent: "flex-end",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
};

const cardFoo = {
    backgroundColor: "#fff",
    px: 1,
    py: 3,
};
