import React, { useState, useEffect, Fragment } from "react";
import {
    Container,
    Grid,
    Box,
    Typography,
    CircularProgress,
    Stack,
} from "@mui/material";
import PageTitle from "../../../hooks/page-title";
import API from "../../../axios";
import { CusProgress, FileCard } from "../../../components";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../../../assets";

export default function Files() {
    const { selectedLang, language } = useSelector((state) => state.storeReducer);
    PageTitle(language[selectedLang]?.categories_categories);

    const [categories, setCategories] = useState(null);
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [page] = useState(1);

    const id = useLocation()?.state?.id;
    const category_id = useLocation()?.state?.category_id;
    const folder_name = useLocation()?.state?.folder_name;
    const folder_id = useLocation()?.state?.folder_id;
    const progress = useLocation()?.state?.progress;
    const navigate = useNavigate();

    useEffect(() => {
        getCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const getCategories = async () => {
        setIsLoading(true);
        try {
            let { data } = await API(
                "get",
                `getfiles?category_id=${category_id}&user_id=${id}&folder_id=${folder_id}`
            );
            setCategories(data.categories);
            setUser(data.user);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    return (
        <Container maxWidth="false" sx={{ pt: 3, pb: 3 }} >
            {isLoading ? (
                <Box sx={{ textAlign: "center" }}>
                    <CircularProgress size={30} />
                </Box>
            ) : (
                <Grid container spacing={2}>
                    <Grid container spacing={2} px={4}>
                        <Grid item sm={12} lg={12} xs={12}>
                            {progress === 0 ? null : (
                                <Stack alignItems='center' sx={{ pt: 2 }}>
                                    <CusProgress progress={progress} pStatus={user?.project_status?.id} />
                                </Stack>
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} mt={2} >
                        <Box mb={2}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Box onClick={() => navigate(-1)} sx={{ cursor: "pointer" }}>
                                    <Stack direction="row">
                                        <img
                                            src={BackButton}
                                            alt="image1"
                                            style={{
                                                height: "24px",
                                                marginTop: "auto",
                                                marginBottom: "auto",
                                                paddingRight: "6px",
                                            }}
                                        ></img>
                                        <Typography variant="h6">
                                            {language[selectedLang]?.categories_categories} ({categories?.[0]?.total_files})
                                        </Typography>
                                    </Stack>
                                </Box>
                                {/* </a> */}
                                <Box py={1}>
                                    <a
                                        href="mailto:{user?.email}"
                                        target="_blank"
                                        rel="noReferrer"
                                        style={{ color: "black", textDecoration: "none" }}
                                    >
                                        {user?.name}
                                    </a>
                                </Box>
                            </Stack>
                        </Box>

                    </Grid>
                    <Grid item xs={12} mb={3}>
                        <Typography variant="h5" fontWeight="bold">
                            {`Folder: ${folder_name}`}
                        </Typography>
                    </Grid>
                    <Grid container spacing={2} ml={0}>
                        {!!categories &&
                            categories[0]?.files?.map((v, index) => {
                                return (
                                    <Fragment key={index}>
                                        <Grid item lg={4} sm={6} xs={12} sx={hoverAnimation}>
                                            <FileCard
                                                file={v}
                                                getCategories={getCategories}
                                                created_date={categories?.[0]?.created_date}
                                                image={!!categories ? categories[0]?.bg_url : ""}
                                                isManager={false}
                                            />
                                        </Grid>
                                    </Fragment>
                                );
                            })
                        }
                    </Grid>
                </Grid>
            )}
        </Container>
    );
}

const hoverAnimation = {
    "&:hover": {
        transition: "all 150ms ease",
        zIndex: 99,
        transform: "scale(1.006)",
    },
};