import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Box,
  Switch,
  Stack,
  Typography,
} from "@mui/material";

import { useDispatch } from "react-redux";
import API from "../../../axios";
import { InputField, PasswordInputField } from "../../../components";
import { openPopUp } from "../../../store/reducer";
import { useSelector } from "react-redux";
export default function AdminForm({
  item = null,
  afterSubmit = () => {},
  closeForm,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    status: true,
    type: "ADD",
  });
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  const [secureEntry, setSecureEntry] = useState(true);
  const [formErrors, setFormErrors] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!item) {
      setFormData({
        ...formData,
        id: item.id,
        name: item.name,
        email: item.email,
        status: item.active,
        type: "EDIT",
        _method: "patch",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    var { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setFormErrors({});
    setIsLoading(true);
    let _url =
      formData.type === "ADD"
        ? "superadmin/admins"
        : `superadmin/admins/${formData.id}`;
    let _fd = {
      name: formData.name,
      email: formData.email,
      status: formData.status === true ? 1 : 0,
    };
    if (formData.type === "EDIT") {
      _fd._method = "patch";
    } else {
      _fd.password = formData.password;
    }
    try {
      await API("post", _url, _fd);
      setIsLoading(false);
      if (formData.type === "ADD") {
        dispatch(
          openPopUp({
            message: language[selectedLang]?.admin_create,
            type: "success",
          })
        );
      } else {
        dispatch(
          openPopUp({
            message: language[selectedLang]?.admin_update,
            type: "success",
          })
        );
      }
      afterSubmit();
    } catch (err) {
      setIsLoading(false);
      let errObj = {};
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else {
        dispatch(
          openPopUp({
            message:
              err?.response?.data?.message || language[selectedLang]?.error_msg,
            type: "error",
          })
        );
      }
    }
  };

  return (
    <Box component="form" autoComplete="off" onSubmit={handleForm}>
      <Box maxWidth="500px" mx="auto">
        <Stack spacing={2}>
          <InputField
            type="text"
            label={language[selectedLang]?.common_name}
            name="name"
            placeholder="Admin"
            required
            value={formData.name}
            error={formErrors?.name}
            onChange={handleChange}
          />
          {formData.type === "ADD" && (
            <>
              <InputField
                type="email"
                label={language[selectedLang]?.common_email}
                name="email"
                placeholder="admin@example.com"
                required
                value={formData.email}
                error={formErrors?.email}
                onChange={handleChange}
              />
              <PasswordInputField
                label={language[selectedLang]?.common_password}
                name="password"
                placeholder="password"
                required
                onChange={handleChange}
                value={formData.password}
                error={formErrors?.password}
                secureEntry={secureEntry}
                setSecureEntry={setSecureEntry}
              />
            </>
          )}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">
              {language[selectedLang]?.common_status}
            </Typography>
            <Switch
              checked={formData.status}
              onChange={(e) =>
                setFormData({ ...formData, status: e.target.checked })
              }
            />
          </Stack>
          <Box textAlign="end">
            <Button onClick={closeForm} variant="contained" sx={{ mr: "4px" }}>
              {language[selectedLang]?.common_cancel}
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={
                !formData.name ||
                !formData.email ||
                !formData.password ||
                isLoading
              }
            >
              {isLoading && <CircularProgress size={16} color="primary" />}
              {formData.type === "ADD"
                ? language[selectedLang]?.common_add
                : language[selectedLang]?.common_save}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
