import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  useTheme,
  useMediaQuery,
  Box,
  Collapse,
  Stack,
  Typography
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SendIcon from "@mui/icons-material/Send";
import CreateIcon from "@mui/icons-material/Create";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Groups3Icon from "@mui/icons-material/Groups3";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import TranslateIcon from "@mui/icons-material/Translate";
import QRCode from "react-qr-code";
import StaffIcon from "@mui/icons-material/SupervisedUserCircle";
import "../../assets/styles.css"

import { Logo, NoImg } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/reducer";
import API from "../../axios";
import { Link } from "react-router-dom";

const _paths = {
  SUPER_ADMIN: [
    {
      title: "super_admin_manage_user",
      route: "/",
      icon: <HomeIcon />,
    },
    {
      title: "categories_categories",
      route: "/categories",
      icon: <CategoryIcon />,
    },

    {
      title: "super_admin_project_status",
      route: "/project-status",
      icon: <AutoAwesomeMosaicIcon />,
    },
    {
      title: "super_admin_settings",
      route: "/settings",
      icon: <SettingsIcon />,
      subMenu: [
        {
          text: "super_admin_send_from_email",
          path: "/settings/send-from-email",
          icon: <SendIcon />,
        },
        {
          text: "super_admin_account_creation",
          path: "/settings/account-creation",
          icon: <CreateIcon />,
        },
        {
          text: "super_admin_account_activation",
          path: "/settings/account-activation",
          icon: <SettingsIcon />,
        },
        {
          text: "super_admin_account_suspension",
          path: "/settings/account-suspension",
          icon: <SettingsIcon />,
        },
        {
          text: "super_admin_update_project",
          path: "/settings/update-project-status",
          icon: <SettingsIcon />,
        },
        {
          text: "super_admin_reset_password",
          path: "/settings/reset-password-email",
          icon: <RestartAltIcon />,
        },
      ],
    },
    {
      title: "super_admin_staffs",
      route: "/staff",
      icon: <StaffIcon />
    },
    {
      title: "super_admin_translations",
      route: "/translation",
      icon: <TranslateIcon />,
    },
  ],
  ADMIN: [
    {
      title: "admin_users",
      route: "/projects",
      icon: <Groups3Icon />,
    },
    {
      title: "admin_staffs",
      route: "/staffs",
      icon: <StaffIcon />,
    },
  ],
  CLIENT: [
    {
      title: "client_files",
      route: "/files",
      icon: <InsertDriveFileIcon />,
    },
  ],
};
export default function Aside({ open, toggleDrawer }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.storeReducer);
  const [staff, setStaff] = useState(null);
  const qrUrl = window.location.href + "&mode=qr&project=" + user.id;


  const signOut = () => {
    API("post", "logout");
    dispatch(logout());
  };

  const changeRoute = (r) => {
    let url;
    if (user?.project_status) {
      url = `${r}?status=${user?.project_status}`;
    } else {
      url = r;
    }
    return navigate(url);
  };

  const openQrCodeForPrinter = () => {
    window.open(`/${process.env.REACT_APP_BASE_NAME}/qr?${qrUrl}`, "_blank");
  };

  useEffect(() => {
    getStaff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getStaff = async () => {
    try {
      let { data } = await API(
        "get",
        `categories?active=1&user_id=${user.id}`
      );
      setStaff(data?.project?.staff_member);
    } catch (error) {
    }
  };

  return (
    <>
      <Drawer
        variant={isMdUp ? "permanent" : "temporary"}
        anchor="left"
        open={open}
        onClose={toggleDrawer}
      >
        <Stack justifyContent="space-between" sx={{ minHeight: "100vh" }}>
          <Box>
            <List sx={{ mt: 2 }}>
              <ListItem>
                <Box sx={{ margin: "0 auto" }}>
                  <Link
                    to={
                      user.role === "SUPER_ADMIN"
                        ? "/"
                        : user.role === "ADMIN"
                          ? "/projects"
                          : user.role === "CLIENT" && "/files"
                    }
                  >
                    <div>
                      <img src={Logo} alt="logo" width="150px" />
                    </div>
                  </Link>
                </Box>
              </ListItem>
            </List>
            <div style={{ margin: "1rem 0" }}>
              <Divider />
            </div>
            <List className="listItemStyle">
              {_paths[user.role].map((_v, _i) => (
                <NavItem
                  key={_i}
                  text={_v.title}
                  path={_v.route}
                  myFunction={() => changeRoute(_v.route)}
                  icon={_v.icon}
                  subMenu={_v.subMenu ? _v.subMenu : ""}
                />
              ))}
              <NavItem
                text={"layout_logout"}
                myFunction={signOut}
                icon={<LogoutIcon sx={{ color: "secondary.dark" }} />}
                subMenu=""
              />
            </List>
          </Box>
          {user?.role === "CLIENT" && (
            <Box mt={2} mb={2}>
              {staff?.image_url && (
                <div style={{ width: 220, height: 170, margin: '0 auto', boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 4px 2px" }}>
                  <img
                    src={staff?.image_url ? staff?.image_url : NoImg}
                    alt="staff member"
                    className="asideImage" />
                </div>
              )}
              <Typography
                variant="label"
                style={{ margin: "4px 4px 4px 20px", color: "secondary.dark" }}
                component="h4"
                ml={2} mt={2}
              >
                {staff?.first_name} {staff?.last_name}
              </Typography>
              <a href={'mailto:' + staff?.email} style={{ textDecoration: "none" }}>
                <Typography
                  variant="label"
                  style={{ margin: "4px 4px 4px 20px", color: "#A0A0A0" }}
                  component="h5"
                  ml={2} mt={2}
                >
                  {staff?.email}
                </Typography></a>
              <Typography
                variant="label"
                style={{ margin: "4px 4px 4px 20px", color: "#A0A0A0" }}
                component="h5"
                ml={2} mt={2}
                noWrap={true}
              >
                {staff?.description}
              </Typography>
            </Box>
          )}
          <Box
            onClick={openQrCodeForPrinter}
            sx={{ paddingBottom: 4, position: "relative", cursor: "pointer" }}
          >
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 100,
                width: "100%",
              }}
            >
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={qrUrl}
                viewBox={`0 0 256 256`}
                level="H"
              />
            </div>
            <div
              style={{
                fontSize: "0.54em",
                wordBreak: "break-all",
                textAlign: "center",
                margin: "0 12px",
              }}
            >
              {qrUrl}
            </div>
            <div style={{ width: "100%", position: "absolute", top: "40px" }}>
              <div
                style={{
                  background: "#FFFFFF",
                  textAlign: "center",
                  width: "32px",
                  margin: "0 auto",
                }}
              >
                <img
                  key={Date.now()}
                  className="qr-icon center"
                  src={Logo}
                  alt="logo"
                  height={16}
                  width={32}
                ></img>
              </div>
            </div>
            <Box sx={{ margin: "12px", textAlign: "center", fontSize: "12px" }}>
              <a href="mailto:support@gama-portal.ch?subject=Gama-Portal%20Meldung">
                support@gama-portal.ch
              </a>
            </Box>
          </Box>
        </Stack>
      </Drawer>
    </>
  );
}

const NavItem = (props) => {

  const [open, setOpen] = React.useState();
  const location = useLocation();
  var routeName = location.pathname;
  const navigate = useNavigate();

  const { selectedLang, language } = useSelector((state) => state.storeReducer);

  return (
    <>
      {props?.subMenu?.length !== 0 ? (
        // <ListItem sx={{ padding: 0, flexDirection: 'column' }}>
        <>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setOpen(!open)} sx={linkStyles}>
              <ListItemIcon>{props.icon}</ListItemIcon>
              <ListItemText
                primary={language[selectedLang]?.[props.text]}
                sx={{ color: "secondary.main" }}
              />
              {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </ListItemButton>
          </ListItem>
          {/* <List sx={{ width: '90%', padding: 0 }}> */}
          <Collapse in={open} timeout="auto" unmountOnExit>
            {props?.subMenu?.map((_item, _i) => {
              return (
                <ListItemButton
                  onClick={() => navigate(_item.path)}
                  selected={_item.path === routeName}
                  sx={[
                    linkStyles,
                    {
                      "& .MuiTypography-root": {
                        fontSize: "14px",
                      },
                      "& .MuiListItemIcon-root ": {
                        paddingLeft: "15px !important",
                      },
                    },
                  ]}
                  pl={3}
                  key={_i}
                >
                  <ListItemIcon>{_item.icon}</ListItemIcon>
                  <ListItemText
                    primary={language[selectedLang]?.[_item.text]}
                    sx={{ color: "secondary.main" }}
                  />
                </ListItemButton>
              );
            })}
          </Collapse>
          {/* </List> */}
        </>
      ) : (
        <ListItem sx={{ padding: 0 }}>
          <ListItemButton
            onClick={props.myFunction}
            selected={props.path === routeName}
            sx={linkStyles}
          >
            <ListItemIcon>{props.icon}</ListItemIcon>
            <ListItemText
              primary={language[selectedLang]?.[props.text]}
              sx={{ color: "secondary.main" }}
            />
          </ListItemButton>
        </ListItem>
      )}
    </>
  );
};

const linkStyles = {
  "&.Mui-selected": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    "& *": {
      color: "primary.main",
      transition: "0.2s all ease-in-out",
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      "& *": {
        color: "primary.main",
      },
    },
    "&::before": {
      height: "100%",
    },
  },
  "&::before": {
    content: `''`,
    position: "absolute",
    top: "50%",
    right: "0",
    transform: "translateY(-50%)",
    height: "0",
    width: "4px",
    backgroundColor: "primary.main",
    transition: "0.2s all ease-in-out",
  },
  "&:hover::before": {
    height: "100%",
  },
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    "& *": {
      color: "primary.main",
      transition: "0.2s all ease-in-out",
    },
  },
};
