import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import PageTitle from "../../../hooks/page-title";
import API from "../../../axios";
import { CusProgress, CustomModal, FolderCard } from "../../../components";
import { openPopUp } from "../../../store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { BackButton } from "../../../assets";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FileCard from "../../../components/file-card/file-card";

const fileTypes = [
  "pdf",
  "jpg",
  "jpeg",
  "png",
  "doc",
  "docx",
  "csv",
  "svg",
  "zip",
];

export default function Files() {
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  PageTitle(language[selectedLang]?.categories_categories);

  const [fileId, setFileId] = useState("")
  const [folderId, setFolderId] = useState("")
  const [openFolAdd, setOpenFolAdd] = useState(false);
  const [openFolderDel, setOpenFolderDel] = useState(false);
  const [openFileDel, setOpenFileDel] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [categories, setCategories] = useState(null);
  const [folderData, setFolderData] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [page] = useState(1);
  const id = useLocation()?.state?.id;
  const category_id = useLocation()?.state?.category_id;
  const progress = useLocation()?.state?.progress;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getCategories = async () => {
    setIsLoading(true);
    try {
      let { data } = await API(
        "get",
        `admin/getfiles?category_id=${category_id}&user_id=${id}&folder_id=0`
      );
      setCategories(data.categories);
      setFolderData(data.folders);
      setUser(data.user);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleUpload = async (file) => {
    if (file === null) {
      dispatch(openPopUp({ message: language[selectedLang]?.select_file, type: "warning" }));
      return;
    }
    let arr = [];
    for (let i = 0; i < file.length; i++) {
      let singleImage = file[i];
      arr.push(singleImage);
    }
    setUploadLoader(true);
    let fd = new FormData();
    arr.forEach((v, i) => {
      fd.append(`file_name[${i}]`, v);
    });
    fd.append(`user_id`, user?.id);
    fd.append(`category_id`, category_id);
    try {
      await API("post", "admin/files", fd);
      setUploadLoader(false);
      dispatch(openPopUp({ message: language[selectedLang]?.upload_file, type: "success" }));
      getCategories();
    } catch (err) {
      if (err?.response?.status === 422) {
        dispatch(
          openPopUp({
            message: err?.response?.data?.details?.file_name[0],
            type: "error",
          })
        );
      } else {
        dispatch(
          openPopUp({
            message: language[selectedLang]?.error_msg,
            type: "error",
          })
        );
      }
      setUploadLoader(false);
    }
  };

  const AddFolderName = async () => {
    let fd = new FormData();
    fd.append(`user_id`, user?.id);
    fd.append(`category_id`, category_id);
    fd.append(`name`, folderName);
    try {
      await API("post", "admin/add-folder", fd);
      getCategories();
      dispatch(openPopUp({ message: "Folder add successFully", type: "success" }));
    } catch (error) {
      dispatch(openPopUp({ message: "Folder adding filed", type: "error" }));
    }
  }

  const deleteFolder = async () => {
    // setIsLoading(true);
    try {
      await API("post", `admin/delete-folder/${folderId}`, "");
      getCategories();
      dispatch(openPopUp({ message: "Folder Deleted SuccessFully", type: "success" }));
    } catch (error) {
      dispatch(openPopUp({ message: "Folder Deleted Failed!", type: "error" }));
    }
  }

  const deleteFile = async () => {
    setIsLoader(true);
    try {
      await API("delete", `admin/files/${fileId}`);
      setIsLoader(false);
      dispatch(openPopUp({ message: language[selectedLang]?.tooltip_delete_file, type: "success" }));
      getCategories();
    } catch (error) {
      setIsLoader(false);
      dispatch(
        openPopUp({
          message: language[selectedLang]?.error_msg,
          type: "error",
        })
      );
    }
  };

  return (
    <Container maxWidth="false" sx={{ pt: 3, pb: 3 }} >
      {isLoading ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid container spacing={2} px={4}>
            <Grid item sm={12} lg={12} xs={12}>
              {progress === 0 ? null : (
                <Stack alignItems='center' sx={{ pt: 2 }}>
                  <CusProgress progress={progress} pStatus={user?.project_status?.id} />
                </Stack>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} mt={2} >
            <Box mb={2}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box onClick={() => navigate(-1)} sx={{ cursor: "pointer" }}>
                  <Stack direction="row">
                    <img
                      src={BackButton}
                      alt="image1"
                      style={{
                        height: "24px",
                        marginTop: "auto",
                        marginBottom: "auto",
                        paddingRight: "6px",
                      }}
                    ></img>
                    <Typography variant="h6">
                      {language[selectedLang]?.categories_categories} ({categories?.[0]?.total_files + folderData?.length})
                    </Typography>
                  </Stack>
                </Box>
                {/* </a> */}
                <Box py={1}>
                  <a
                    href="mailto:{user?.email}"
                    target="_blank"
                    rel="noReferrer"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {user?.name}
                  </a>
                </Box>
              </Stack>
            </Box>

            <Box mb={3}>
              <Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between" gap={4}>
                <Stack direction="row" alignItems="flex-end">
                  <img
                    src={!!categories ? categories[0]?.image : undefined}
                    width="auto"
                    height="30px"
                    style={{ marginRight: "5px" }}
                    alt="icon.png"
                  />
                  <Typography variant="h6">
                    {!!categories && categories[0]?.name}
                  </Typography>
                </Stack>
                <Stack direction={{ xs: "column", md: "row" }} alignItems="flex-end" spacing={2} >
                  <Button
                    variant="contained"
                    startIcon={<CreateNewFolderIcon />}
                    onClick={() => {
                      setOpenFolAdd(true)
                    }}
                    sx={{ py: "11px" }}
                  >
                    {language[selectedLang]?.add_folder}
                  </Button>
                  {uploadLoader ? (
                    <CircularProgress size={32} color="primary" />
                  ) : (
                    // <Box sx={{ width: { sx: "100%", md: "50%" } }}>
                    <FileUploader
                      handleChange={(e) => handleUpload(e)}
                      name="file"
                      multiple
                      maxSize={500}
                      types={fileTypes}
                    />
                    // </Box>
                  )}
                </Stack>
              </Stack>
            </Box>

          </Grid>

          {!!folderData?.length &&
            <Grid item xs={12} mb={2}>
              <Typography variant="h5" fontWeight="bold">
                {language[selectedLang]?.folders}
              </Typography>
            </Grid>
          }
          <Grid container spacing={2} mb={2} ml={0}>
            {!!folderData &&
              folderData?.map((_v, index) => {
                return (
                  <Fragment key={index}>
                    <Grid item lg={4} sm={6} xs={12} sx={hoverAnimation}>
                      <FolderCard
                        file={_v}
                        id={id}
                        progress={progress}
                        category_id={category_id}
                        setOpenFolderDel={setOpenFolderDel}
                        setFolderId={setFolderId}
                        isManager={true}
                      />
                    </Grid>
                  </Fragment>
                );
              })
            }
          </Grid>

          <Grid item xs={12} mb={3}>
            <Typography variant="h5" fontWeight="bold">
              {language[selectedLang]?.files}
            </Typography>
          </Grid>
          <Grid container spacing={2} ml={0}>
            {!!categories &&
              categories[0]?.files?.map((v, index) => {
                return (
                  <Fragment key={index}>
                    <Grid item lg={4} sm={6} xs={12} sx={hoverAnimation}>
                      <FileCard
                        file={v}
                        getCategories={getCategories}
                        created_date={categories?.[0]?.created_date}
                        image={!!categories ? categories[0]?.bg_url : ""}
                        setOpenFileDel={setOpenFileDel}
                        setFileId={setFileId}
                        isManager={true}
                      />
                    </Grid>
                  </Fragment>
                );
              })
            }
          </Grid>
        </Grid>
      )
      }
      {
        openFolAdd ? (
          <CustomModal
            handleClose={() => setOpenFolAdd(false)}
            children={
              <Box p={1}>
                <Stack direction="column" mb={3}>
                  <Typography variant="h6" sx={{ textAlign: "center" }}>
                    {language[selectedLang]?.enter_folder_name}
                  </Typography>
                  <TextField
                    label="Folder Name"
                    variant="standard"
                    onChange={(e) => {
                      setFolderName(e.target.value);
                    }}
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  <Button variant="contained" onClick={() => setOpenFolAdd(false)}>
                    {language[selectedLang]?.common_no}
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => {
                      AddFolderName();
                      setOpenFolAdd(false);
                    }}
                    disabled={isLoading}
                  >
                    {isLoading && <CircularProgress size={16} color="primary" />}
                    {language[selectedLang]?.common_yes}
                  </Button>
                </Stack>
              </Box>
            }
          />
        ) : null
      }
      {
        openFolderDel ? (
          <CustomModal
            handleClose={() => setOpenFolderDel(false)}
            children={
              <Box py={3}>
                <Typography mb={2} variant="h6" sx={{ textAlign: "center" }}>
                  {language[selectedLang]?.delete_message_message}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  <Button variant="contained" onClick={() => setOpenFolderDel(false)}>
                    {language[selectedLang]?.common_no}
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    onClick={(_id) => {
                      deleteFolder(_id);
                      setOpenFolderDel(false)
                    }}
                    disabled={isLoading}
                  >
                    {isLoading && <CircularProgress size={16} color="primary" />}
                    {language[selectedLang]?.common_yes}
                  </Button>
                </Stack>
              </Box>
            }
          />
        ) : null
      }
      {
        openFileDel ? (
          <CustomModal
            handleClose={() => setOpenFileDel(false)}
            children={
              <Box py={3}>
                <Typography mb={2} variant="h6" sx={{ textAlign: "center" }}>
                  {language[selectedLang]?.delete_message_message}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  <Button variant="contained" onClick={() => setOpenFileDel(false)}>
                    {language[selectedLang]?.common_no}
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => {
                      deleteFile()
                      setOpenFileDel(false);
                    }}
                    disabled={isLoader}
                  >
                    {isLoader && <CircularProgress size={16} color="primary" />}
                    {language[selectedLang]?.common_yes}
                  </Button>
                </Stack>
              </Box>
            }
          />
        ) : null
      }
    </Container >
  );
}

const hoverAnimation = {
  "&:hover": {
    transition: "all 150ms ease",
    zIndex: 99,
    transform: "scale(1.006)",
  },
};