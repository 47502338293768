import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import PageTitle from "../../../hooks/page-title";
import API from "../../../axios";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProStatus } from "../../../store/reducer";
import { CusProgress } from "../../../components";
import { BackButton } from "../../../assets";

export default function AdDropFiles() {
  const [categories, setCategories] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(1);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  PageTitle(language[selectedLang]?.user_user)

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getCategories = async () => {
    setIsLoading(true);
    try {
      let { data } = await API(
        "get",
        `admin/categories?active=1&user_id=${id}`
      );
      setCategories(data.categories);
      localStorage.setItem("@Selected_User", data.user?.name);
      setUser(data.project);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <Container maxWidth="false" sx={{ pt: 3, pb: 3 }}>
      <Grid container spacing={2} mt={1}>
        <Grid container spacing={2} px={2}>
          <Grid item sm={12} lg={12} xs={12}>
            {user?.project_status === null ? null : user?.project_status
              ?.progress === 0 ? null : (
              <CusProgress progress={user?.project_status
                ?.progress} pStatus={user?.project_status?.id} />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Stack direction="row" justifyContent="space-between">
              {/* <a
                target="_self"
                href="/#;"
                rel="noReferrer"
                style={{ color: "black", textDecoration: "none" }}
              > */}
              <Box onClick={() => navigate(-1)} sx={{ cursor: 'pointer' }}>
                <Stack direction="row">
                  <img
                    src={BackButton}
                    alt="cdnImg"
                    style={{
                      height: "24px",
                      marginTop: "auto",
                      marginBottom: "auto",
                      paddingRight: "6px",
                    }}
                  ></img>
                  <Typography variant="h6">
                    {language[selectedLang]?.categories_categories}
                  </Typography>
                </Stack>
              </Box>
              {/* </a> */}
              <Box py={1}>
                <a
                  href="mailto:{user?.email}"
                  target="_blank"
                  rel="noReferrer"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  {user?.name}
                </a>
              </Box>
            </Stack>
          </Box>
          {/* <Typography
            variant="small"
            color="#FFBB00"
            sx={{ cursor: "pointer", marginBottom: 2, marginLeft: 4 }}
            onClick={() => navigate(-1)}
          >
            Project/
          </Typography> */}
        </Grid>


        {isLoading ? (
          <Grid item xs={12}>
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress size={22} />
            </Box>
          </Grid>
        ) : (
          !!categories &&
          categories?.map((v, i) => {
            return (
              <Grid item lg={4} sm={6} xs={12} key={i}>
                <Box sx={cardContainer}>
                  <Box
                    sx={[
                      cardStyle,
                      {
                        backgroundImage: user.image_url
                          ? `url(${user.image_url})`
                          : `url(${v.bg_url})`,
                      },
                    ]}
                    onClick={() => {
                      dispatch(setProStatus(user?.project_status?.title));
                      navigate("/project/category", {
                        state: {
                          id: user.id,
                          category_id: v.id,
                          progress: user?.project_status
                            ? user?.project_status?.progress
                            : 0,
                        },
                      });
                    }}
                  >

                  </Box>
                  <Box sx={cardFoo}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Box>
                        <img
                          style={smallLogo}
                          src={v.image_url}
                          alt="logo"
                          width="40px"
                          height="40px"
                        />
                      </Box>
                      <Typography
                        sx={{ fontSize: 20, fontWeight: "bold" }}
                        variant="small"
                      >
                        {v.name}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </Grid>
            );
          })
        )}
      </Grid>
    </Container >
  );
}


const cardAnimation = {
  transition: "all 150ms ease",
  boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 4px 2px",
  zIndex: 99,
  transform: "scale(1.006)",
};

const cardContainer = {
  boxShadow: "rgba(0, 0, 0, 0.4) 1px 1px 4px 1px",
  borderRadius: "12px",
  position: "relative",
  overflow: "hidden",
  cursor: "pointer",
  height: "100%",
  "&:hover": cardAnimation,
};
const cardStyle = {
  width: "100%",
  minHeight: "250px",
  display: "flex",
  justifyContent: "flex-end",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
};

const cardFoo = {
  backgroundColor: "#fff",
  px: 1,
  py: 3, // Todo: check if height is set that way correct
};
const smallLogo = {
  width: "auto",
  height: "24px",
};
