import React, { useEffect, useState } from 'react';
import {
    Container,
    Box,
    Stack,
    Button,
    CircularProgress,
    Typography
} from '@mui/material';
import { InputField } from '../../../components';
import API from '../../../axios';
import { openPopUp } from '../../../store/reducer';
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";

export default function SendFromEmail() {
    const [isLoading, setIsLoading] = useState(false);
    const [pageLoad, setPageLoad] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
    });
    const { selectedLang, language } = useSelector((state) => state.storeReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        getAccountCreation();
    }, []);

    const getAccountCreation = async () => {
        setPageLoad(true)
        try {
            let { data } = await API('get', 'superadmin/get-from-email');
            setFormData({
                email: data?.value,
            })
            setPageLoad(false);
        } catch (error) {
            console.log('ERR =>', error)
            setPageLoad(false);
        }
    }

    const handleForm = async () => {
        setIsLoading(true)
        try {
            let { data } = await API('post', 'superadmin/update-from-email', {
                "email": formData?.email,
            });
            if (data) {
                dispatch(openPopUp({ message: language[selectedLang]?.common_updated_successfuly, type: "success" }));
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            dispatch(openPopUp({ message: language[selectedLang]?.error_msg, type: "error" }));
        }
    }

    return (
        <Container maxWidth="false" sx={{ pt: 3, pb: 3 }}>
            {
                pageLoad ?
                    <Box component='div' sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={28} color="primary" />
                    </Box>
                    :
                    <Box>
                        <Typography
                            variant="h5"
                            component="h5"
                            sx={{
                                fontWeight: "bold",
                                textAlign: 'center'
                            }}
                        >
                            {language[selectedLang]?.super_admin_send_from_email}
                        </Typography>
                        <Stack spacing={2}>
                            <InputField
                                type='email'
                                label={language[selectedLang]?.common_email}
                                name="subject"
                                placeholder="Email"
                                value={formData.email}
                                required
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            />
                            <Box>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={isLoading}
                                    onClick={handleForm}
                                >
                                    {isLoading && (
                                        <CircularProgress size={16} color="primary" />
                                    )}
                                    { language[selectedLang]?.common_update}
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
            }
        </Container>
    )
}
