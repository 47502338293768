import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  Stack,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UsersIcon from "@mui/icons-material/SupervisedUserCircle";
import { openPopUp, setProStatus } from "../../../store/reducer";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../hooks/page-title";
import {
  InputField,
  TableWrapper,
  UsePagination,
  SelectBox,
} from "../../../components";
import API from "../../../axios";
import UserForm from "./user-form";
import { useNavigate } from "react-router-dom";
import { CustomModal } from "../../../components";

export default function AdUsers() {
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  PageTitle(language[selectedLang]?.user_user);
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [Id, setId] = useState();
  const [openM2, setOpenM2] = useState(false);
  const [singleRecord, setSingleRecord] = useState(null);
  const [record, setRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const toggleForm = () => setShowForm(!showForm);
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [projectStatus] = useState([]);
  const [staffMember] = useState([]);
  const [formData, setFormData] = useState({
    search: "",
    user_status: "",
    project_status: "",
    staff_member: "",
  });

  useEffect(() => {
    getRecord({});
    getProjectStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getProjectStatus = async () => {
    setIsLoading(true);
    try {
      let { data } = await API("get", "get-status-and-staff");
      data?.project_statuses?.map((_v) => {
        let obj = {
          label: _v?.title,
          value: _v?.id,
        };
        return projectStatus.push(obj);
      });
      data?.staff_list?.map((_v) => {
        let obj = {
          label: _v?.first_name,
          value: _v?.id,
        };
        return staffMember.push(obj);
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    var { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const clearForm = async () => {
    setFormData({
      ...formData,
      search: "",
      user_status: "",
      project_status: "",
      staff_member: "",
    });

    getRecord("");
  };

  const getRecord = async (type) => {

    setIsLoading(true);
    try {
      let url;
      if (type === "search") {
        url = `admin/users?page=${page}&search=${formData.search}&user_status=${
          formData.user_status === "all" ? "" : formData.user_status
        }&project_status=${formData.project_status}`;
      } else {
        url = `admin/users?page=${page}`;
      }
      let { data } = await API("get", url);
      setRecord(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const updateRecord = (item) => {
    setSingleRecord(item);
    setShowForm(true);
  };

  const addRecord = () => {
    if (!!singleRecord) {
      setSingleRecord(null);
    }
    toggleForm();
  };

  const handleDelete = async () => {
    setIsLoader(true);
    try {
      await API("get", `admin/user/delete/${Id}`);
      setIsLoader(false);
      dispatch(
        openPopUp({
          message: language[selectedLang]?.delete_user,
          type: "success",
        })
      );
      setOpenM2(false);
      getRecord("search");
    } catch (error) {
      setIsLoader(false);
      dispatch(
        openPopUp({
          message: language[selectedLang]?.error_msg,
          type: "error",
        })
      );
    }
  };

  return (
    <Container maxWidth="false" sx={{ pt: 3, pb: 3 }}>
      <Box component="form" onSubmit={(e) => {e.preventDefault();getRecord("search")}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography component="h6" variant="h6">
                {language[selectedLang]?.user_user}
              </Typography>
              {!showForm && (
                <Button onClick={addRecord} variant="contained">
                  {language[selectedLang]?.common_add}
                </Button>
              )}
            </Stack>
          </Grid>

          {!showForm && (
            <>
              <Grid item xs={12} sm={6} lg={3}>
                <InputField
                  type="text"
                  label={language[selectedLang]?.common_search}
                  name="search"
                  placeholder={language[selectedLang]?.customer_name}
                  required
                  color="primary"
                  value={formData.search}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <SelectBox
                  items={[
                    { label: language[selectedLang]?.common_all, value: "all" },
                    {
                      label: language[selectedLang]?.common_active,
                      value: "active",
                    },
                    {
                      label: language[selectedLang]?.common_notactive,
                      value: "not-active",
                    },
                  ]}
                  label={language[selectedLang]?.common_user_status}
                  name="user_status"
                  size="small"
                  initValue={formData.user_status}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <SelectBox
                  items={projectStatus}
                  label={language[selectedLang]?.project_status_project_status}
                  name="project_status"
                  size="small"
                  initValue={formData.project_status}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item sm={6} lg={3}>
                <Stack direction="row" alignItems="center">
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={
                      !formData.project_status &&
                      !formData.search &&
                      !formData.user_status
                    }
                    // onClick={() => getRecord('search')}
                    sx={{ marginRight: 1 }}
                  >
                    {language[selectedLang]?.common_search}
                  </Button>
                  <Button
                  disabled={
                     !formData.project_status &&
                      !formData.search &&
                      !formData.user_status
                  }
                   variant="contained"
                    onClick={() => clearForm()}>
                    {language[selectedLang]?.common_clear}
                  </Button>
                </Stack>
              </Grid>
            </>
          )}
          {showForm && (
            <Grid item xs={12}>
              <UserForm
                item={singleRecord}
                projectStatus={projectStatus}
                staffMember={staffMember}
                afterSubmit={() => {
                  getRecord("");
                  toggleForm();
                }}
                closeForm={() => addRecord()}
              />
            </Grid>
          )}
          {!showForm && (
            <Grid item xs={12} sx={{ overflow: "auto" }}>
              <TableWrapper
                thContent={
                  <TableRow>
                    <TableCell sx={{ color: "#fff" }}>
                      {language[selectedLang]?.common_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        display: { xs: "none", sm: "none", md: "table-cell" },
                      }}
                    >
                      {language[selectedLang]?.admin_staff_member}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        display: {
                          xs: "none",
                          sm: "table-cell",
                          md: "table-cell",
                        },
                      }}
                    >
                      {language[selectedLang]?.project_status_project_status}
                    </TableCell>
                    <TableCell sx={{ color: "#fff" }}>
                      {language[selectedLang]?.common_action}
                    </TableCell>
                  </TableRow>
                }
                spanTd={4}
                isLoading={isLoading}
                isContent={!!record?.data.length}
              >
                {record?.data.map((v, i) => (
                  <TableRow key={i}>
                    <TableCell>{v?.name}</TableCell>
                    <TableCell
                      sx={{
                        display: { xs: "none", sm: "none", md: "table-cell" },
                      }}
                    >
                      {v.staff_member ? v.staff_member.first_name : "--"}
                    </TableCell>
                    <TableCell
                      sx={{
                        display: {
                          xs: "none",
                          sm: "table-cell",
                          md: "table-cell",
                        },
                      }}
                    >
                      {v.project_status?.title ? v.project_status?.title : "--"}
                    </TableCell>
                    <TableCell>
                      <Tooltip title={language[selectedLang]?.edit}>
                        <IconButton
                          onClick={() => updateRecord(v)}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={language[selectedLang]?.drop_files_files}>
                        <IconButton
                          onClick={() => {
                            dispatch(
                              setProStatus(v?.project_status?.common_title)
                            );
                            navigate(`/project/${v.id}`);
                          }}
                          color="primary"
                        >
                          <UploadFileIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={language[selectedLang]?.admin_t_users}>
                        <IconButton
                          onClick={() => {
                            navigate(`/projects/users/${v.id}`, {
                              state: {
                                project_Id: v?.id,
                              },
                            });
                          }}
                          color="primary"
                        >
                          <UsersIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={language[selectedLang]?.delete}>
                        <IconButton
                          onClick={() => {
                            setOpenM2(true);
                            setId(v.id);
                          }}
                          color="error"
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableWrapper>
              {!!record && record?.last_page > 1 && (
                <Box component="div" sx={{ mt: 2 }}>
                  <UsePagination
                    total={record?.total}
                    perPage={record?.per_page}
                    page={page}
                    setPage={setPage}
                  />
                </Box>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            {!showForm && !!record && record?.last_page > 1 && (
              <Box component="div" sx={{ mt: 2 }}>
                <UsePagination
                  total={record?.total}
                  perPage={record?.per_page}
                  page={page}
                  setPage={setPage}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      {openM2 ? (
        <CustomModal
          handleClose={() => setOpenM2(false)}
          children={
            <Box py={3}>
              <Typography mb={2} variant="h6" sx={{ textAlign: "center" }}>
                {language[selectedLang]?.delete_message_message}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Button variant="contained" onClick={() => setOpenM2(false)}>
                  {language[selectedLang]?.common_no}
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => handleDelete()}
                  disabled={isLoader}
                >
                  {isLoader && <CircularProgress size={16} color="primary" />}
                  {language[selectedLang]?.common_yes}
                </Button>
              </Stack>
            </Box>
          }
        />
      ) : null}
    </Container>
  );
}
