import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Box,
  Stack,
  Tooltip,
  FormHelperText,
} from "@mui/material";

import { useDispatch } from "react-redux";
import API from "../../../axios";
import { InputField } from "../../../components";
import { openPopUp } from "../../../store/reducer";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { NoImg } from "../../../assets";
export default function StaffForm({
  item = null,
  afterSubmit = () => {},
  closeForm,
}) {
  const fileTypes = ["jpg", "png", "jpeg"];
  const [isLoading, setIsLoading] = useState(false);
  const [imageBlob, setImageBlob] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    description: "",
    image: '',
    image_url: "",
    type: "ADD",
  });

  const handleUpload = async (file) => {
    if (file === null) {
      dispatch(
        openPopUp({
          message: language[selectedLang]?.select_file,
          type: "warning",
        })
      );
      return;
    }
    setFormData({ ...formData, image: file });
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!item) {
      setFormData({
        ...formData,
        id: item?.id,
        first_name: item?.first_name,
        last_name: item?.last_name,
        email: item?.email,
        description: item?.description,
        image: item?.image,
        image_url: item?.image_url,
        type: "EDIT",
        _method: "patch",
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    var { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const _fd = new FormData();
    _fd.append("first_name", formData.first_name);
    _fd.append("last_name", formData.last_name);
    formData.type === "ADD" && _fd.append("email", formData.email);
    _fd.append("description", formData.description);
    formData?.image?.name && _fd.append("image", formData?.image);
    let _url =
      formData.type === "ADD"
        ? "superadmin/staffs"
        : `superadmin/staffs/${formData.id}`;
    if (formData.type === "EDIT") {
      _fd.append("_method", "patch");
    }
    try {
      await API("post", _url, _fd);
      setIsLoading(false);
      if (formData.type === "ADD") {
        dispatch(
          openPopUp({
            message: language[selectedLang]?.staff_create,
            type: "success",
          })
        );
      } else {
        dispatch(
          openPopUp({
            message: language[selectedLang]?.staff_update,
            type: "success",
          })
        );
      }
      afterSubmit();
    } catch (err) {
      setIsLoading(false);
      let errObj = {};
      if (err?.response?.status === 422) {
        for (const [key, value] of Object.entries(
          err?.response?.data?.detail
        )) {
          errObj = { ...errObj, [key]: value[0] };
        }
        setFormErrors(errObj);
      } else {
        dispatch(
          openPopUp({
            message:
              err?.response?.data?.message || language[selectedLang]?.error_msg,
            type: "error",
          })
        );
      }
    }
  };

  const handleImage = (_file) => {
    let blob = URL.createObjectURL(_file);
    setImageBlob(blob);
  };

  return (
    <Box component="form" autoComplete="off" onSubmit={handleForm}>
      <Box maxWidth="500px" mx="auto">
        <Stack spacing={2}>
          <Box>
            <InputField
              type="text"
              name="first_name"
              label={language[selectedLang]?.common_fname}
              placeholder="First Name"
              error={formErrors?.first_name}
              required
              value={formData?.first_name}
              onChange={handleChange}
            />
          </Box>
          <Box>
            <InputField
              type="text"
              name="last_name"
              label={language[selectedLang]?.common_lname}
              placeholder="Last Name"
              error={formErrors?.last_name}
              required
              value={formData?.last_name}
              onChange={handleChange}
            />
          </Box>
          {formData.type === "ADD" && (
            <InputField
              type="email"
              label={language[selectedLang]?.common_email}
              name="email"
              placeholder="client@example.com"
              required
              error={formErrors?.email}
              value={formData?.email}
              onChange={handleChange}
            />
          )}
          <Box>
            <InputField
              type="text"
              label={language[selectedLang]?.common_description}
              name="description"
              placeholder="Description..."
              error={formErrors?.description}
              value={formData.description}
              onChange={handleChange}
            />
          </Box>
          <Box>
            {formData?.image?.name ? (
              <ItemLabel title="Icon" url={formData.image_url} />
            ) : (
              <Box variant="p" mb={1.5}>
                Icon
              </Box>
            )}
            <FileUploader
              handleChange={(e) => {
                handleUpload(e);
                handleImage(e);
              }}
              name="image"
              required
              multiple={false}
              maxSize={200}
              types={fileTypes}
            />
            {formErrors?.image && (
              <FormHelperText sx={{ color: "primary.main" }}>
                {formErrors?.image}
              </FormHelperText>
            )}
            <Box width="100%" height="100%" marginTop={3}>
              <img
                src={
                  imageBlob
                    ? imageBlob
                    : formData.image_url
                    ? formData.image_url
                    : NoImg
                }
                width="auto"
                style={{ maxWidth: "100%" }}
                height="auto"
                alt="test"
              />
            </Box>
          </Box>
          <Box textAlign="end">
            <Button onClick={closeForm} variant="contained" sx={{ mr: "4px" }}>
              {language[selectedLang]?.common_cancel}
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={
                !formData.first_name ||
                !formData.last_name ||
                !formData.email ||
                !formData.image ||
                isLoading
              }
            >
              {isLoading && <CircularProgress size={16} color="primary" />}
              {formData.type === "ADD"
                ? language[selectedLang]?.common_add
                : language[selectedLang]?.common_save}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}

const ItemLabel = ({ title, url }) => {
  const { selectedLang, language } = useSelector((state) => state.storeReducer);
  return (
    <Box style={{ display: "flex", alignItems: "center" }}>
      <Box variant="p" mb={1} mr={1}>
        {title}
      </Box>
      <a
        href={url}
        target="_blank"
        rel="noReferrer"
        style={{ color: "black", textDecoration: "none" }}
      >
        <Tooltip title={language[selectedLang]?.previous_image} placement="top">
          <RemoveRedEyeIcon
            fontSize="medium"
            style={{
              cursor: "pointer",
              color: "black",
              marginTop: "3px",
            }}
          />
        </Tooltip>
      </a>
    </Box>
  );
};
